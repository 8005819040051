
//next
.next-enter {
  opacity: 0;
  position: absolute;
  overflow: hidden;
  height: calc(100vh - 3.75rem);
  left: 0;
  right: 0;
}
.next-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease;
}
.next-exit {
  opacity: 1;
}
.next-exit-active {
  opacity: 0;
  transition: opacity 0.5s ease;
  position: absolute;
  overflow: hidden;
  height: calc(100vh - 3.75rem);
  left: 0;
  right: 0;
}

//slide left
.slide-left-enter {
  position: absolute !important;
  left: 0;
  right: 0;
  transform: translateX(150%);
  opacity: 0;
}

.slide-left-enter-active {
  transition: transform 0.8s ease, opacity 0.8s ease;
  transform: translateX(0%);
  opacity: 1;
}

.slide-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-left-exit-active {
  position: absolute !important;
  left: 0;
  right: 0;
  transition: transform 0.8s ease, opacity 0.8s ease;
  transform: translateX(-150%);
  opacity: 0;
}

//left
.left-enter {
  transform: translateX(100%);
  opacity: 0;
}

.left-enter-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
  transform: translateX(0%);
  opacity: 1;
}

.left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.left-exit-active {
  position: absolute;
  top: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
  transform: translateX(-100%);
  opacity: 0;
}

//firstload
.firstload {
  transition: none !important;
}

