@import '../styles/colors.scss';
@import '../styles/breakpoints.scss';

.score {
	position: absolute;
	right: 0;
	top: 0;
	width: 1.5625rem;
	height: 1.5625rem;
	border-radius: 1.5625rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $happy_blue;
	border: 0.1875rem $white solid;
	font-size: 0.75rem;
	line-height: 0.75rem;
	font-weight: bold;
	color: $white;
	padding-top: 0.0938rem;
}

.scorebig {
	z-index: 15;
	position: absolute;
	left: 0;
	right: 0;
	bottom: -1.4375rem;
	margin: auto;
	width: 4.0625rem;
	height: 4.0625rem;
	border-radius: 4.0625rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $happy_blue;
	border: 0.3125rem $white solid;
	font-size: 2.5rem;
	line-height: 0.75rem;
	font-weight: bold;
	color: $white;
	padding-top: 0.0938rem;
}

.bottomblock,
.bottomblockorange,
.bottomblockwhite {
	position: absolute;
	top: 0;
	left: -100vw;
	width: 400vw;
	height: 100%;
}

.bottomblockorange {
	background-color: $orange_red;
}

.bottomblockwhite {
	background-color: $white;
	min-height: 100vh;
	height: 200%;
}

.wave {
	background-size: contain;
	background-position: bottom center;
	position: absolute;
	top: -0.5625rem;
	left: -100vw;
	background-repeat: repeat-x;
	width: 400vw;
	height: 0.5625rem;
	z-index: 5;

	@include lg {
		top: -0.9375rem;
		height: 0.9375rem;
	}
}

.player {
	.btn {
		display: none;
	}
}

.player.showplayerbtn {
	.btn {
		display: block;
	}
}

.video {
	border: 0.625rem solid $white;
	width: 100%;
}

.btn {
	.loader {
		display: none;
	}
	.txt {
		display: block;
	}
	&.loading {
		-webkit-tap-highlight-color: transparent;
		cursor: not-allowed;
		
		.loader {
			display: block;
			animation: 0.45s linear 0s infinite loader;
		}
		.txt {
			display: none;
		}
	}
	&.refresh {
		.refreshicon {
			animation: 1s linear 0s infinite loader;
		}
	}
	@keyframes loader {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
}
