@import '../styles/colors.scss';
@import '../styles/breakpoints.scss';

.pagecontainer {
	top: 0.5625rem;
	@include lg {
		top: 0.9375rem;
	}
}

.bottomblock,
.bottomblockblue,
.bottomblockwhite {
	position: absolute;
	top: 0;
	left: -100vw;
	width: 400vw;
	height: 100%;
}

.bottomblockblue {
	background-color: $happy_blue;
}

.bottomblockwhite {
	background-color: $white;
	min-height: 100vh;
	height: 200%;
}

.blockbottom {
	position: relative;
	top: -0.5625rem;
	@include lg {
		top: -0.9375rem;
	}
}

.wave {
	background-size: contain;
	background-position: bottom center;
	position: absolute;
	top: -0.5625rem;
	left: -100vw;
	background-repeat: repeat-x;
	width: 400vw;
	height: 0.5625rem;
	z-index: 5;

	@include lg {
		top: -0.9375rem;
		height: 0.9375rem;
	}
}

.link {
	text-decoration: underline;
	color: $happy_blue;
}