@import '../styles/colors.scss';
@import '../styles/breakpoints.scss';

.playcontainer {
	top: 0.5625rem;
	@include lg {
		top: 0.9375rem;
	}
}

.bottomblock, .bottomblockwhite {
	background-color: $happy_blue;
	position: absolute;
	top: 0;
	left: -100vw;
	width: 400vw;
	height: 100%;
	min-height: 200vh;
}

.bottomblockwhite {
	background-color: $white;
	min-height: 100vh;
	height: 200%;
}

.wave {
	background-size: contain;
	background-position: bottom center;
	position: absolute;
	top: -0.5625rem;
	left: -100vw;
	background-repeat: repeat-x;
	width: 400vw;
	height: 0.5625rem;
	z-index: 5;
	@include lg {
		top: -0.9375rem;
		height: 0.9375rem;
	}
}

.btn {
	.loader {
		display: none;
	}
	.txt {
		display: block;
	}
	&.loading {
		-webkit-tap-highlight-color: transparent;
		cursor: not-allowed;
		
		.loader {
			display: block;
			animation: 0.45s linear 0s infinite loader;
		}
		.txt {
			display: none;
		}
	}
	&.refresh {
		.refreshicon {
			animation: 1s linear 0s infinite loader;
		}
	}
	@keyframes loader {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
}
