@import '../styles/colors.scss';
@import '../styles/breakpoints.scss';

.bottomblock {
	background-color: $white;
	position: absolute;
	top: 0;
	left: -100vw;
	width: 200vw;
	height: 100vh;
}

.wave {
	background-size: contain;
	background-position: bottom center;
	position: absolute;
	top: -0.5625rem;
	left: -100vw;
	background-repeat: repeat-x;
	width: 400vw;
	height: 0.5625rem;
	z-index: 5;
	@include lg {
		top: -0.9375rem;
		height: 0.9375rem;
	}
}