@import '../styles/colors.scss';
@import '../styles/breakpoints.scss';

.collapse {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding-top: 3.25rem;
	z-index: 100;
}

.mobilemav {
	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: -100%;
		left: 0;
		background-color: $happy_blue;
	}
}

.hamburger {
	display: flex;
	align-items: center;
	justify-content: center;
}

.link {
	position: relative;
	transition: color .5s ease-in-out;
	&::before {
		content: "";
		background-color: $happy_blue;
		width: 1.6875rem;
		height: 0.5625rem;
		position: absolute;
		left: -1rem;
		top: 50%;
		transform: translateY(-50%);
		opacity: 0;
		transition: opacity 0.25s ease-in-out;
	}
	&:hover {
		text-decoration: underline;
	}
}

.active {
	&::before {
		opacity: 1;
	}
	@include lg {
		span {
			text-decoration: underline;
		}
		&::before {
			opacity: 0;
		}
	}
}

.hamburger {
	path {
		transition: fill .25s ease-in-out;
	}
}

.hamburgerwhite {
	path {
		fill: $white;
	}
}

.hamburgerorangered {
	path {
		fill: $orange_red;
	}
}

.itemnightblue {
	color: $night_blue;
}

.itemorangered {
	color: $orange_red;
}

.itemwhite {
	color: $white;
}

.wave {
	background-size: contain;
	background-position: top center;
	position: absolute;
	top: 0;
	left: -50vw;
	background-repeat: repeat-x;
	width: 200vw;
	height: 0.5625rem;
	z-index: 5;
	@include lg {
		height: 0.9375rem;
	}
}
