$screen-sm-min: 30em;
$screen-md-min: 48em;
$screen-lg-min: 62em;
$screen-xl-min: 80em;
$screen-twoxl-min: 96em;

@mixin sm {
	@media (min-width: #{$screen-sm-min}) {
			@content;
	}
}

@mixin md {
	@media (min-width: #{$screen-md-min}) {
			@content;
	}
}

@mixin lg {
	@media (min-width: #{$screen-lg-min}) {
			@content;
	}
}

@mixin xl {
	@media (min-width: #{$screen-xl-min}) {
			@content;
	}
}

@mixin twoxl {
	@media (min-width: #{$screen-twoxl-min}) {
			@content;
	}
}
